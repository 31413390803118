/* Base Styles */
body {
  font-family: "Roboto", sans-serif; /* A Google Font, ensure it's imported in your HTML */
}

/* Searchbox */
.searchbox {
  display: flex;
  align-items: center;
  height: 2.5em;
  background-color: #f5f5f5;
  border-radius: 25px; /* Rounded corners */
  padding: 0 15px; /* Padding to left and right of the searchbox */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* subtle shadow */
  transition: background-color 0.3s ease;
}

.searchbox:hover {
  background-color: #e9e9e9; /* A little darker on hover */
}

.svg-inline--fa {
  height: 0.8em;
}

.searchbox__input {
  width: 9em;
  flex: 1; /* Take up maximum width */
  font-size: 16px;
  padding: 8px;
  border: none;
  background: transparent;
  outline: none;
  font-weight: 400;
  color: #333;
  margin-right: 10px; /* Space before the search icon */
}

.searchbox__input--ltr {
  margin-right: 0;
}
/* For RTL direction */
.searchbox--rtl {
  /* flex-direction: row-reverse; */
  justify-content: space-between;
}

/* For LTR direction */
.searchbox--ltr {
  flex-direction: row;
}

.searchbox__icon--ltr {
  margin-right: 8px;
}

.searchbox__icon--rtl {
  margin-left: 8px;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .searchbox {
    padding: 0 10px; /* Slightly reduce padding */
  }
}

.kinzbook__navbar-search {
  width: 34%; /* A default width that takes up a significant portion of the navbar */
  max-width: 800px; /* Maximum width to ensure it doesn't get overly wide on large screens */
  /* min-width: 250px;  */
}

@media (max-width: 768px) {
  .kinzbook__navbar-search {
    width: 80%;
  }
}
@media (max-width: 550px) {
  .kinzbook__navbar-search {
    width: 95%;
  }
}
