.book-container {
  /* Add the long list of CSS properties here */
  /* Just a subset for the example */
  display: grid;
  border: 1px solid rgb(227, 230, 239);
  height: 328px;
  width: 190px;
  border: 1px solid #e3e6ef;
  padding: 0 16px;
}

.book-image {
  margin-top: 19px;
}
.kinzbook__book-image,
.kinzbook__book-image-placeholder {
  height: 149.48px;
  width: 149.48px;
}

.kinzbook__book-image-placeholder {
  background-color: #f5f5f7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #f5f5f7;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgb(0 0 0 / 10%);
}

.kinzbook__book-image-placeholder-title {
  font-size: 1rem;
  color: black !important;
  text-align: center;
  overflow: hidden;
  font-weight: 600;
}
.kinzbook__book-image-placeholder-author {
  color: black !important;
}

.book-info {
  margin-top: 19px;
}
.book-type {
  font-style: normal;
  line-height: 1.5;
  color: #6e6e73;
  font-size: 14px;
}

.book-title {
  margin: 0;
  color: #1c263d;
  font-style: normal;
  line-height: 1.1428571429;
  letter-spacing: 0.007em;
  margin-bottom: 4px;
  font-size: 16px;
  color: #333;
  align-self: center;
  overflow: hidden;
}

.langDir--ltr {
  text-align: left;
  font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial,
    sans-serif;
}

.langDir-rtl {
  text-align: right;
  font-family: var(--bs-body-font-family);
}

.book-author {
  font-size: 1rem;
  color: #06c;
  /* color: rgb(29, 29, 31); */
}

.book-category {
  font-size: 0.9rem;
  color: rgb(29, 29, 31);
}

.book-container {
  flex: 0 0 calc(16.66% - 10px);
  margin-bottom: 20px;
}

.rm-text-dec,
.rm-text-dec * {
  text-decoration: none !important;
}

/* .book-image img,
.kinzbook__book-image-placeholder {
  width: 100%;
  height: auto;
} */

/* Optional: add responsive design */
@media (max-width: 768px) {
  .book-container {
    flex: 0 0 calc(33.33% - 10px);
  }
}

@media (max-width: 576px) {
  .book-container {
    flex: 0 0 calc(50% - 10px);
  }
}
