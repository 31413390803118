:root {
  --font-family: "Noto Kufi Arabic", "Open Sans", "Helvetica Neue", Helvetica,
    Arial, sans-serif;

  --globalnav-background: rgba(232, 232, 237, 0.4);

  --gradient-text: linear-gradient(89.97deg, #736b62 1.84%, #f49867 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #736b62 -13.86%, #f49867 99.55%);

  --color-bg: #040c18;
  --color-footer: #232f3e;
  --footer-link-color: #fff;
  --footer-background: #f5f5f7;

  --footer-background: #f5f5f7;
  --footer-border-color: #d2d2d7;
  --footer-text-color: #6e6e73;
  --footer-link-color: #424245;
  --footer-directory-title-color: #1d1d1f;
  /* --color-footer : #031B34; */
  --color-blog: #042c54;
  --color-text: #8b8a88;
  /* --color-text: #81AFDD; */
  --color-subtext: #736b62;
  /* --bs-body-font-family: "Amiri", serif; */
  --bs-body-font-family: "Noto Kufi Arabic", "Open Sans", "Helvetica Neue",
    Helvetica, Arial, sans-serif;
  /* --color-subtext: #FF8A71; */
}

/* .letterSpacing-ar {
  font-family: "Noto Kufi Arabic", "Open Sans", "Helvetica Neue", Helvetica,
    Arial, sans-serif;
    
} */

body {
  margin: 0;
}
.rm-text-dec,
.rm-text-dec * {
  text-decoration: none !important;
}

.page-overview {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.wrapper {
  /* font-size: 17px;
  line-height: 1.4705882353;
  font-weight: 400;
  letter-spacing: -0.022em;
  font-family: SF Pro Text, SF Pro Icons, Helvetica Neue, Helvetica, Arial,
    sans-serif;
  background-color: #fff;
  color: #1d1d1f;
  font-style: normal; */
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}
/* .navbar-0,
.brandLogo,
#navBar {
  padding: 0;
}

.navbar-brand .brandLogo {
  font-size: xx-large;
}
#kinzbook__navbar-links_logo {
  width: 185px;
  height: 50px;
  cursor: pointer;
} */
