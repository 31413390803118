.kinzbook__audio-player {
  direction: ltr;
}
.rhap_container {
  font-family: "Arial", sans-serif;
}

.rhap_volume-container {
  display: none;
}

/* Container for the progress bar and time */
.rhap_progress-section {
  position: relative;
  padding: 10px; /* Spacing around progress bar */
}

/* Style for the progress bar container */
.rhap_progress-container {
  background-color: rgba(
    255,
    255,
    255,
    0.3
  ); /* Translucent white background for progress bar */
  border-radius: 2px; /* Rounded corners for progress bar */
  height: 4px; /* Thickness of the progress bar */
}

/* Style for the filled part of the progress bar */
.rhap_progress-filled {
  border-radius: 2px; /* Rounded corners for filled part */
}

.rhap_controls-section {
  display: block;
}

/* Style for the current time and total time */
.rhap_time {
  position: absolute;
  font-size: 12px; /* Smaller font size for the time */
}

/* Current time styling */
.rhap_current-time {
  left: 1.2em;
  bottom: -20px; /* Positioning below the progress bar */
}

/* Total time styling */
.rhap_total-time {
  right: 1.5em;
  bottom: -20px; /* Positioning below the progress bar */
}

/* Main controls (play, pause, forward, rewind) */
.rhap_main-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0; /* Spacing above and below controls */
}

/* Play/Pause button styling */
.rhap_play-pause-button {
  margin: 0 20px; /* Spacing around the play/pause button */
}

/* Forward and rewind buttons */
.rhap_forward-button,
.rhap_rewind-button {
  margin: 0 10px; /* Spacing around forward and rewind buttons */
}

/* Volume controls container */
.rhap_volume-controls {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px; /* Spacing around volume controls */
}

/* Volume bar styling */
.rhap_volume-bar {
  width: 100px; /* Fixed width for the volume bar */
}

/* SVG icons color */
svg {
  fill: white; /* Icons color */
}
