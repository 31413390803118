/* Container for book list */

.kinzbook__bookList {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  gap: 2.4em;
}
/* Grid for displaying books */
.kinzbook__bookList-items {
  display: grid;
  grid-template-columns: repeat(6, 1fr); /* Display 6 books per row */
  gap: 20px;
}

#pagination {
  border-radius: 0;
}

/* Styles for mobile responsiveness */
@media only screen and (max-width: 768px) {
}

@media only screen and (max-width: 768px) {
  .kinzbook__container {
    flex-direction: column;
  }
}

/* Original Styles for desktop */
.bayan__container-book_container {
  /* your existing styles */
}

.bayan__container-rss {
  /* your existing styles */
}

/* Media Queries for tablet screens */
@media screen and (max-width: 768px) {
  .bayan__container-book_container {
    /* Adjusted styles for tablets */
  }

  .bayan__container-rss {
    /* Adjusted styles for tablets */
  }
}

/* Media Queries for mobile screens */
@media screen and (max-width: 576px) {
  .bayan__container-book_container {
    /* Adjusted styles for mobile */
  }

  .bayan__container-rss {
    /* Adjusted styles for mobile */
  }
}
