CSS for BookEditScreen Component .book-edit-screen {
  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.book-edit-screen h1 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.book-edit-screen .form-group {
  margin-bottom: 20px;
}

.book-edit-screen .form-label {
  font-weight: bold;
}

.book-edit-screen .form-control {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
}

.book-edit-screen .btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}

.book-edit-screen .btn-outline-info:hover {
  background-color: #17a2b8;
  color: #fff;
}

.book-edit-screen .btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}

.book-edit-screen .btn-outline-success:hover {
  background-color: #28a745;
  color: #fff;
}

.book-edit-screen .btn-danger {
  background-color: #dc3545;
  border-color: #dc3545;
  color: #fff;
}

.book-edit-screen .btn-danger:hover {
  background-color: #c82333;
  border-color: #bd2130;
}

.book-edit-screen .progress {
  margin-top: 10px;
}

.book-edit-screen .drag-container {
  border: 2px dashed #ccc;
  padding: 10px;
  border-radius: 5px;
  background-color: #fff;
}

.book-edit-screen .draggable-item {
  background-color: #f7f7f7;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.book-edit-screen .remove-button {
  background-color: #dc3545;
  border-color: #dc3545;
  color: #fff;
  font-size: 12px;
  padding: 4px 8px;
  border-radius: 3px;
}

.book-edit-screen .remove-button:hover {
  background-color: #c82333;
  border-color: #bd2130;
}

.book-edit-screen .d-grid {
  margin-top: 20px;
}

.book-edit-screen {
  padding: 30px;
  background-color: #f9f9f9; /* Slightly changed for softer look */
  border-radius: 8px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  font-family: "Poppins", sans-serif; /* New font */
}

.book-edit-screen h1 {
  font-size: 26px;
  font-weight: 600; /* Slightly lesser weight for a softer look */
  margin-bottom: 25px;
}

.book-edit-screen .form-group {
  margin-bottom: 25px;
}

.book-edit-screen .form-label {
  font-weight: 500;
}

.book-edit-screen .form-control {
  border: 1px solid #ddd; /* Slightly lighter border */
  border-radius: 8px;
  padding: 12px;
  transition: border 0.3s; /* Smooth transition on focus */
}

.book-edit-screen .form-control:focus {
  border-color: #17a2b8; /* Adding a focus effect */
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.25); /* Optional focus shadow */
}

/* ... Rest of the styles remain the same ... */

.book-edit-screen .btn {
  border-radius: 20px; /* More rounded corners */
  transition: background-color 0.3s, border-color 0.3s; /* Smooth transition */
}

/* ... Rest of the styles remain the same ... */
