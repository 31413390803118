.kinzbook__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(251, 251, 253);
}
.kinzbook__header-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.kinzbook__navbar-logo img {
  width: 150px;
}
.kinzbook__navbar {
  margin: 1em;
}
.kinzbook__navbar-globalnav {
  display: flex;
  gap: 1em;
  align-items: center;
  margin: 0;
}

.kinzbook__navbar-language {
  position: relative;
}

.kinzbook__select {
  padding: 5px 30px 5px 10px;
  border: none;

  appearance: none;
  background-color: transparent;

  outline: none;
}

.kinzbook__select:focus {
  box-shadow: none;
}

.kinzbook__select-container {
  position: relative;
}

.kinzbook__select-container::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: transparent;
}

.kinzbook__select-arrow {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 8px solid #000;
  pointer-events: none;
}

.kinzbook__navbar-controls-dropdownItem {
  text-decoration: none;
  color: black;
  font-size: 1rem;
}

.kinzbook__navbar-controls-userDropdown {
  align-items: center;
  background-color: #f0f2f9;
  border-radius: 100%;
  border: 1px solid #f0f2f9;
  box-sizing: border-box;
  display: flex;
  height: 36px;
  justify-content: center;
  width: 36px;
}

.kinzbook__navbar-sign,
.kinzbook__navbar-toggle-controls-navLink {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.kinzbook__navbar-sign button,
.kinzbook__navbar-toggle-controls-navLink button {
  --button-size-large: 2.5em;
  --button-size-small: 2em;
  background: #ff4820;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  line-height: 25px;
  outline: none;
  min-height: 2.5em;
  padding: 8px;
}
.kinzbook__navbar-sign button a,
.kinzbook__navbar-toggle-controls-navLink button a {
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
}

.kinzbook__navbar-controls-userDropdown .userIcon {
  color: #303140;
}
.kinzbook__navbar-controls-userDropdown::after {
  display: none;
}

.kinzbook__navbar-controls-userDropdown:hover,
.kinzbook__navbar-controls-userDropdown:focus {
  background-color: #f0f2f9;
  box-shadow: none;
  --bs-btn-focus-shadow-rgb: none;
}

.kinzbook__navbar-menu {
  position: relative;
}

.kinzbook__button-link {
  text-decoration: none;
}

.kinzbook__button {
  border: none;
  border-radius: 20px;
  background-color: transparent;
  color: #333336;
  padding: 10px 20px;
  transition: background-color 0.3s, color 0.3s;
}

.kinzbook__button-icon {
  margin-right: 5px;
}

.kinzbook__button:hover,
.kinzbook__button:focus {
  background-color: transparent;
  color: #000000;
}

.kinzbook__button:active {
  background-color: #555;
  color: #fff;
}

.kinzbook__navbar-toggle {
  display: none;
}

.kinzbook__navbar-toggle {
  margin-left: 1rem;

  display: none;
  position: relative;
}

.kinzbook__navbar-toggle svg {
  cursor: pointer;
}

.kinzbook__navbar-toggle-userDropdown::after {
  box-shadow: none;
  border: none;
}

.kinzbook__navbar-collapse {
  display: none;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: max-height 0.5s, opacity 0.5s 0.5s, visibility 0s 1s;
}

/* Visible state */
.bestabib__rating-section-review-form.visible {
  max-height: 500px;
  opacity: 1;
  visibility: visible;
  /* transition: max-height 1s, opacity 1s, visibility 0s; */
  transition: max-height 1s ease, opacity 0.5s ease;
}
.kinzbook__navbar-toggle-controls-userSection {
  display: none;
}
/* Mobile view */
@media (max-width: 768px) {
  .kinzbook__navbar-collapse.visible {
    max-height: 100%;
    opacity: 1;
    visibility: visible;
    /* transition: max-height 1s, opacity 1s, visibility 0s; */
    transition: max-height 0.5s ease, opacity 0.5s ease;
  }
  .kinzbook__navbar-collapse {
    height: 100%;
    width: 264px;
    overflow-y: auto;
    position: fixed;
    background-color: #fbfbfd;
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 31;
    display: flex;
    flex-direction: column;
    transition: all 10s ease-in-out;

    transition: color 0.32s cubic-bezier(0.4, 0, 0.6, 1);
    transition-duration: 0.32s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
    transition-delay: 0s;
    transition-property: color;
  }

  .kinzbook__navbar-collapse-menu p a {
    padding: 24px;
    padding-top: 50px;
    font-size: 28px;
    line-height: 1.1428571429;
    font-weight: 600;
    letter-spacing: 0.007em;
    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial,
      sans-serif;
    text-decoration: none;
    color: #333336;
  }
  .kinzbook__navbar-collapse--rtl {
    right: 0;
  }
  .kinzbook__navbar-collapse-topBar {
    align-items: center;
    background-color: #f2f2f2;
    /* background-color: #f9faff; */
    display: flex;
    justify-content: space-between;
    padding: 19px 24px;
  }
  /* .kinzbook__navbar-search {
    display: none; 
  } */

  .kinzbook__navbar-toggle {
    display: flex;
  }

  .kinzbook__navbar-menu,
  .kinzbook__select-container,
  .kinzbook__navbar-controls-navLink,
  .kinzbook__navbar-sign {
    display: none;
  }
  .kinzbook__navbar-toggle--rtl {
    float: right; /* move to the beginning in RTL mode */
  }

  .kinzbook__navbar-toggle svg {
    width: 1.5em;
    height: 1.5em;
    margin: 19px;
  }

  .kinzbook__navbar-controls-userDropdown {
    display: none;
  }
  .kinzbook__navbar-toggle-userDropdown,
  .dropdown-toggle {
    display: flex;
    font-family: var(--spl-font-family-sans-serif-primary), sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 1.25rem;
    line-height: 1.3;
    color: var(--color-slate-400);
    padding-left: var(--space-size-xs);
    margin: 0.9em;
    word-break: break-word;
    justify-content: space-between;
    gap: 24px;
    text-transform: capitalize;
    background-color: transparent;
    border: none;
    color: #303140;
    box-shadow: none;
    padding: 0;
  }
  .kinzbook__navbar-toggle-userDropdown:hover,
  .kinzbook__navbar-toggle-userDropdown:focus {
    border: none; /* Remove the border */
    background-color: transparent; /* Remove the background color */
    color: inherit; /* Inherit the text color from the parent */
    text-decoration: none; /* Remove text decorations */
    cursor: pointer;
    color: #303140;
    box-shadow: none;
  }

  .kinzbook__navbar-toggle-language {
    position: relative;
    padding: 24px;
  }

  .kinzbook__select-toggle {
    padding: 1px 3px 1px 3px;
    font-size: 16px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    appearance: none;
    cursor: pointer;
  }

  /* Hide default HTML select arrow in Firefox */
  .kinzbook__select::-moz-focus-inner {
    border: 0;
  }

  /* Hide default HTML select arrow in IE10+ */
  .kinzbook__select::-ms-expand {
    display: none;
  }

  /* Hover and focus styles */
  .kinzbook__select:hover,
  .kinzbook__select:focus {
    border-color: #666;
  }
  .kinzbook__navbar-controls-userSection {
    display: flex;
    justify-content: center;
  }

  .kinzbook__navbar-toggle-controls-navLink {
    margin-top: 2em;
    padding: 24px;
  }

  /* .searchbox__input {
    padding-inline-end: inherit;
    font-size: 0.8rem;
    font-weight: 500;
  } */

  .kinzbook__navbar-toggle-controls-userSection {
    display: flex;
  }
  .kinzbook__navbar-toggle-userDropdown {
    display: none;
  }
}

@media (max-width: 550px) {
  .kinzbook__header {
    height: 4em;
  }
  .kinzbook__header--rtl {
    padding-right: 1em;
  }
  .kinzbook__header--ltr {
    padding-left: 1em;
    /* margin-inline-end: 18em;
    width: 242px; */
  }
  .kinzbook__navbar-controls-userSection {
    display: flex;
    justify-content: center;
    margin-top: 2em;
    /* padding: 24px; */
  }
  .kinzbook__navbar-logo img {
    width: 119px;
  }

  .kinzbook__header-container {
    width: 100%;
  }

  .kinzbook__navbar-controls-userDropdown {
    display: none;
    justify-content: center;
  }

  .kinzbook__navbar-toggle-userDropdown {
    display: flex;
    gap: 0.5em;
  }

  .kinzbook__navbar-toggle-userDropdown .userIcon {
    line-height: 100%;
  }
  .kinzbook__navbar-toggle svg {
    margin: 0px;
  }
  .kinzbook__navbar-toggle {
    margin-left: 0rem;
  }
  .kinzbook__navbar-toggle-controls-userSection {
    display: flex;
  }
}
