.rhap_container,
.rhap_container *:focus {
  outline: none;
}
.rhap_horizontal {
  flex-direction: column;
}

.rhap_main-controls-button * {
  margin: 0 3px;
  /* color: #868686; */
  font-size: 35px;
  width: 35px;
  height: 35px;
  background: #fff;
  color: #000000;
}
.rhap_container {
  margin-top: 20px;
  box-shadow: none;
}

.kinzbook__background {
  background-color: #fff;
}

.kinzbook__bookScreenPage {
  padding-top: 50px;
  padding-bottom: 50px;
}

.kinzbook__bookScreen-image {
  border-radius: 8px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.kinzbook__audio-players {
  margin-top: 20px;
}

.kinzbook__audio-player {
  margin-bottom: 20px;
}

.kinzbook__bookScreen-title {
  font-size: 1.8rem;
  color: #333;
}

.kinzbook__bookScreen-author {
  font-size: 1rem;
  color: #666;
}

.kinzbook__subtitle {
  font-size: 0.9rem;
  color: #999;
}

.kinzbook__bookScreen-description {
  font-size: 1rem;
  line-height: 1.5;
  color: #555;
}

.kinzbook__hr {
  border: 0.5px solid #eee;
  margin-top: 15px;
  margin-bottom: 15px;
}

.kinzbook__btn-download {
  background-color: #0073e6;
  color: white;
  margin-top: 10px;
}

.kinzbook__btn-download:hover {
  background-color: #005bb5;
}

.kinzbook__bookScreen-image-placeholder {
  background-color: #f5f5f7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #f5f5f7;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.kinzbook__bookScreen-image-placeholder,
.kinzbook__bookScreen-image {
  height: 232px;
  width: 232px;
  margin: auto;
}

.kinzbook__bookScreen-image-placeholder-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
}

.kinzbook__bookScreen-image-placeholder-author {
  font-size: 16px;
  text-align: center;
}

@media (max-width: 768px) {
  .kinzbook__bookScreen-image,
  .kinzbook__bookScreen-image-placeholder {
    margin: 20px auto;
  }
  .kinzbook__audio-players {
    padding: 2.4em;
  }
}

.kinzbook__background {
  background-color: #ffffff;
  color: #333333;
}

.kinzbook__bookScreenPage {
  padding: 60px 0;
}

.kinzbook__bookScreen-image {
  border-radius: 10px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.kinzbook__bookScreen-image:hover {
  transform: scale(1.05);
}

.kinzbook__audio-players {
  margin-top: 30px;
}

/* .rhap_container {
  background-color: #f9f9f9;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
} */

.kinzbook__bookScreen-title {
  font-size: 2rem;
  font-weight: 600;
  color: #444444;
  margin-bottom: 20px;
}

.kinzbook__bookScreen-author {
  font-size: 1.2rem;
  color: #777777;
}

.kinzbook__hr {
  border: 0.5px solid #eeeeee;
}

.kinzbook__btn-download {
  display: inline-block;
  background-color: #0073e6;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 25px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.3s ease;
  text-decoration: none;
  align-items: center;
  cursor: pointer;
}

.kinzbook__btn-download:hover {
  background-color: #0077ed;
  color: #fff;
}
